@font-face {
  font-family: "Halcyon-Regular";
  src: local("Halcyon-Regular"), url(./assets/fonts/Halcyon-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Halcyon-Semibold";
  src: local("Halcyon-Semibold"), url(./assets/fonts/Halcyon-SemiBold.ttf) format("truetype");
}

@font-face {
  font-family: "Halcyon-Medium";
  src: local("Halcyon-Medium"), url(./assets/fonts/Halcyon-Medium.ttf) format("truetype");
}

.p-column-title {
  color: #1A69FA !important;
}

.pi-data {
  cursor: pointer;
  color: black !important;
  padding: 5px !important;
  background: #F6F6F6 !important;
  border-radius: 15px !important;
  font-size: 12px !important;
  margin-left: 3em !important;
}

.btn-primary {
  background-color: #1A69FA !important;
  border-color: #1A69FA !important;
}

.tablebar {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.blue {
  color: #1A69FA !important;
}

.grey {
  color: #1F1E1D !important;
}

.segmentheader {
  font-size: 32px !important;
  font-family: "Halcyon-Semibold";
}

.tableheader {
  font-size: 25px !important;
  font-family: "Halcyon-Semibold";
}

.bold {
  font-family: "Halcyon-Semibold";
}

.profileLabel {
  cursor: pointer;
  color: black;
  font-family: "Halcyon-Semibold";
  font-size: 16px !important;
}

img {
  cursor: pointer;
}

.p-datatable .p-datatable-footer {
  border: 0 !important;
  background: white !important;
  border-bottom-left-radius: 15px !important;
  border-bottom-right-radius: 15px !important;
}

.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td:first-child {
  border-top-left-radius: 9px !important;
  border-bottom-left-radius: 9px !important;
}

.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td:last-child {
  border-top-right-radius: 9px !important;
  border-bottom-right-radius: 9px !important;
}

.p-dialog {
  border-radius: 10px !important;
  font-family: 'Halcyon-Semibold' !important;
  text-align: center !important;
  font-size: 25px !important;
}

.p-dialog .p-dialog-header .p-dialog-title {
  font-size: 25px !important;
}

.p-dialog .p-dialog-header {
  padding: 3.5rem 1.5rem 3.5rem 1.5rem !important;
  justify-content: center !important;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}

.p-dialog .p-dialog-footer {
  padding: 1.5rem 1.5rem 3.5rem 1.5rem !important;
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
}

.p-fileupload .p-fileupload-content .p-messages {
  display: none !important;
}

.saldo {
  -moz-border-radius: 25px;
  border-radius: 25px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 1px;
}

.pi-sort-alt {
  visibility: hidden !important;
  width: 0 !important;
}

.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td {
  padding: 1rem 0.5rem !important;
}

.pi-times-circle:before {
  color: red !important;
}

.pi-check-circle:before {
  color: green !important;
}

.p-datatable-header {
  background: white !important;
}

.p-password {
  width: 100%;
}

.p-datatable {
  border-radius: 15px;
}

.whitearea {
  background: white !important;
  border-radius: 15px !important;
}

.p-datatable-table {
  border-collapse: separate !important;
  background: white !important;
  border-spacing: 0px 8px !important;
  padding: 0.5em !important;
}

.field {
  margin-bottom: 0 !important;
}

.p-datatable .p-datatable-header {
  border: none !important;
}

.p-datatable .p-datatable-tbody>tr {
  color: black !important;
  background: #F6F6F6 !important;
}

.p-datatable .p-datatable-thead>tr>th {
  background: white !important;
  color: #1A69FA !important;
  border: none !important;
}

body,
body .p-component {
  font-family: Halcyon-Regular;
}

::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #a4a4af;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #1A69FA;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1A69FA;
}

.p-fileupload-row>div:first-child {
  display: none;
}

.p-checkbox {
  align-items: center !important;
}

.navbar-nav {
  gap: 10px !important;
}

#Diagimg {
  width: 100%;
  height: auto;
  border-radius: 15px;
  object-fit: contain;
  margin: 0 auto;
}

.p-progress-spinner-svg {
  width: 20% !important;
}

.p-progress-spinner {
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 1000 !important;
  background-color: rgb(0 0 0 / 50%)
}

.loginComponent {
  position: relative;
  top: 10%;
}

.p-button-label {
  font-weight: 800 !important;
}

/* You can add global styles to this file, and also import other style files */
.p-button.p-button-text {
  font-size: 14px !important;
  color: white !important;
}

.tablebar>.p-input-icon-right>.p-inputtext {
  border-radius: 30px !important;
}

.p-checkbox .p-checkbox-box .p-checkbox-icon {
  color: #1A69FA !important;
}

.p-checkbox .p-checkbox-box.p-highlight {
  background: white !important;
  border-color: #1A69FA !important;
}

.p-paginator .p-paginator-pages .p-paginator-page {
  border-radius: 0px !important;
  margin: 0 !important;
  border: solid 1px !important;
  border-color: #CBD2D9 !important;
}

.p-disabled,
.p-component:disabled {
  opacity: 1 !important;
}

.p-paginator .p-paginator-next {
  color: #CBD2D9 !important;
  padding-left: 0.5em !important;
  padding-right: 0.5em !important;
  margin: 0 !important;
  margin-right: 0.5rem !important;
  border-radius: 0 !important;
  border-color: #CBD2D9 !important;
  border-right: solid 1px !important;
  border-top: solid 1px !important;
  border-bottom: solid 1px !important;
  border-top-right-radius: 9px !important;
  border-bottom-right-radius: 9px !important;
}

.p-paginator .p-paginator-prev {
  color: #CBD2D9 !important;
  padding-left: 0.5em !important;
  padding-right: 0.5em !important;
  margin: 0 !important;
  border-radius: 0 !important;
  border-color: #CBD2D9 !important;
  border-left: solid 1px !important;
  border-top: solid 1px !important;
  border-bottom: solid 1px !important;
  border-top-left-radius: 9px !important;
  border-bottom-left-radius: 9px !important;
}

.p-overlaypanel {
  border-radius: 34px !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
}

.p-overlaypanel .p-overlaypanel-content {
  padding: 1.5rem !important;
}

.p-overlaypanel:after,
.p-overlaypanel:before {
  left: calc(var(--overlayArrowLeft, 0)) !important;
}

.p-datatable .p-paginator-bottom .p-dropdown .p-dropdown-trigger {
  color: #1A69FA !important;
  background: white !important;
  border-radius: 9px !important;
}

.p-datatable .p-paginator-bottom .p-dropdown {
  border-radius: 9px !important;
}

.p-button .p-button-icon-left {
  display: none !important;
}

.p-dialog .p-dialog-footer {
  gap: 5% !important;
  display: flex !important;
  flex-direction: row-reverse !important;
  justify-content: center !important;
}

.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  display: none !important;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-highlight .p-menuitem-link {
  border-color: #1A69FA !important;
  color: #1A69FA !important;
}

.p-tabmenu .p-tabmenu-nav .p-tabmenuitem .p-menuitem-link {
  color: #7d7e7e !important;
}

.card-header {
  background-color: white !important;
}

.pi-angle-left:before {
  font-family: Halcyon-Regular !important;
  content: "Wstecz" !important;
  color: #1A69FA !important;
}

.pi-angle-right:before {
  font-family: Halcyon-Regular !important;
  content: "Dalej" !important;
  color: #1A69FA !important;
  margin-right: 0.5em !important;
}

.p-paginator {
  background: #F6F6F6 !important;
}

.loginpage {
  min-height: 100vh;
}

.p-datatable-hoverable-rows .p-selectable-row {
  cursor: unset !important;
}

.p-datatable .p-paginator-bottom {
  padding: 0 !important;
  border: none !important;
  background: white !important;
}

.p-float-label label {
  background: white !important;
  top: 0.25rem !important;
  font-size: 14px !important;
  color: #949494 !important;
}

.page {
  min-height: 91.6vh;
  padding-top: 2.5em;
  padding-left: 2em;
  padding-right: 2em;
}

.p-fileupload-files {
  background: white !important;
  box-shadow: 0px 1px 7px rgb(0 0 0 / 10%), 0px 4px 5px -2px rgb(0 0 0 / 12%), 0px 10px 15px -5px rgb(0 0 0 / 20%) !important;
}

.p-fileupload .p-fileupload-content {
  border-style: dashed !important;
  min-height: 250px !important;
  background-image: url("/assets/ikony pozostale/icon-document-add.svg") !important;
  background-repeat: no-repeat !important;
  background-position: 15% 50% !important;
  background-size: 12vh !important;
}

.backgroundlock {
  background-image: url("/assets/ikony pozostale/icon-lock.svg");
  background-repeat: no-repeat;
  background-position: 90% 97% !important;
  background-size: 60vh !important;
}

.p-datatable-header {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.p-datatable.p-datatable-sm .p-datatable-header {
  padding-bottom: 0.5em !important;
  padding-left: 1em !important;
  padding-top: 0.5em !important;
}

td .p-button {
  background-color: white !important;
  color: black !important;
  padding: 0.5em !important;
}

.p-button {
  border-radius: 10px !important;
  border-color: rgb(255, 255, 255) !important;
  min-width: fit-content !important;
  background-color: #1A69FA !important;
  margin-left: 0.5em !important;
  margin-right: 0.5em !important;
  padding: 0.7em 1.2em !important;
  font-size: 14px !important;
}

.p-steps .p-steps-item .p-menuitem-link {
  background-color: #F6F6F6 !important;
}

.p-steps-item {
  background-color: #F6F6F6 !important;
}

.center {
  text-align: center;
  align-self: center;
}

.header {
  padding: 0.5em;
  padding-left: 1em;
  display: flex;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, .03);
}

.p-grid {
  margin-top: 0.5em !important;
}

.nav-return {
  cursor: pointer;
  position: absolute;
  bottom: 30px;
  color: white;
  margin-left: 2vw
}

.nav-link {
  color: white !important;
  padding-left: 1em !important;
  padding-right: 1em !important;
}

.tabletitle {
  color: #1A69FA;
  margin-top: 15px;
}

.nav-icon {
  cursor: unset;
  height: 31px;
  width: 30px;
  padding: 8px;
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(338deg) brightness(300%) contrast(150%);
}

.active a {
  font-family: 'Halcyon-Semibold';
}

.active img {
  border-radius: 7px;
  background-color: white;
  filter: none !important;
}

.scrollable::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #F5F5F5;
}

.p-datatable-scrollable-body::-webkit-scrollbar {
  width: 12px;
  background-color: #F5F5F5;
}

.p-datatable-scrollable-body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #555;
}

.p-dropdown {
  width: 100% !important;
}

.p-checkbox-label {
  font-size: 12px;
  line-height: 0;
}

a {
  cursor: pointer;
}

.agreement {
  align-items: flex-start !important;
  margin-right: 0.25rem !important;
}

.error {
  display: block;
  color: red;
}

.p-tabmenu {
  height: fit-content;
}

.container {
  position: relative;
  padding: unset;
  max-width: 100%;
}

.logo {
  height: 15em;
}

.p-datatable-responsive .p-datatable-tbody>tr>td .p-column-title {
  display: none;
}

.p-paginator-bottom>.p-inputwrapper {
  order: -1 !important;
  margin-right: auto !important;
}

.pi-chevron-down:before {
  color: #1A69FA !important;
}

.p-fileupload-row {
  display: none !important;
}

.p-fileupload .p-fileupload-buttonbar {
  visibility: hidden !important;
  height: 0 !important;
}

.attachment {
  border-radius: 4px;
  background: #F6F6F6;
  display: flex;
  align-items: baseline;
  font-size: 14px;
}

.revertbutton {
  color: #1A69FA !important;
  border: 1px solid !important;
  border-color: #1A69FA !important;
  background-color: white !important;
}

.navbar {
  height: fit-content;
  z-index: 1001;
  background: white !important;
  justify-content: space-between;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 35, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='3' d='M4 3h25M4 13h21M4 23h17'/%3e%3c/svg%3e")
}

.navbar-collapse {
  top: 0 !important;
  z-index: 1001;
  flex-basis: 25% !important;
  height: 100vh;
  position: sticky;
  background-color: #1A69FA !important;
  border-bottom-right-radius: 40px;
  position: -webkit-sticky;
}

.navbar-collapse.collapsing {
  left: -75%;
  transition: height 0.2s ease;
}

.close-navbar-toggler {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  cursor: pointer;
}

.close-navbar-toggler.collapsed {
  z-index: -1;
}

#inputNumberDiagOrgId {
  display: block !important;
}

.row{
  --bs-gutter-x: unset;
 }

.diagimgdiv
{
  width: 38%; // złota proporcja ;)
}

// 1280px - unlimited
@media screen and (min-width: 1280px) {
  .navbar-collapse {
    flex-basis: 18% !important;
  }
  #Diagimg {
    width: 62%;
  }
}

// 0px - 1279px
@media screen and (max-width: 1279px) {
   .row{
    display: flex;
    margin: unset;
   }
   
   .diagimgdiv
   {
    width:unset;
   }
}

// 560px - unlimited
@media screen and (min-width: 560px) {
  .saldomobile {
    display: none;
  }

  .collapse:not(.show) {
    display: block;
  }

  #nav-but {
    display: none;
  }
}

// 0px - 559px
@media screen and (max-width: 559px) {
  .inputmobile {
    width: 100%;
  }

  .spanmobile {
    width: 50%;
  }

  .nav-link {
    padding: 0.5rem 0.2rem !important;
  }

  .p-steps {
    display: none !important;
  }

  .navbar-collapse {
    z-index: 1002;
    top: 0;
    left: 0;
    position: fixed;
    width: 69%;
  }

  .saldo {
    display: none;
  }

  .col-fixed {
    width: 100% !important;
  }

  .page {
    padding-right: 0.5em;
    padding-left: 0.5em;
  }
}

@media screen and (max-width: 40rem) {
  .p-datatable-responsive {

    .p-datatable-thead>tr>th,
    .p-datatable-tfoot>tr>td {
      display: none !important;
    }

    .p-datatable-tbody>tr>td {
      text-align: left;
      display: block;
      width: 100%;
      float: left;
      clear: left;
      border: 0 none;

      .p-column-title {
        padding: .4rem;
        min-width: 30%;
        display: inline-block;
        margin: -.4em 1em -.4em -.4rem;
        font-weight: bold;
      }

      &:last-child {
        border-bottom: 1px solid var(--surface-d);
      }
    }
  }
}
